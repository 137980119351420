<template>
  <div class="input-container">

    <div class="label-container">
      <FormLabel
        v-if="showLabel"
        :formName="formName"
        :fieldName="fieldName"
        @click="onSelectClick"
      />
      <slot name="label-extra-content" />
    </div>

    <FormLabelDescription
      :formName="formName"
      :fieldName="fieldName"
      v-if="field.labelDescription"
    />

    <div
      ref="selectContainer"
      class="select-container"
      :class="{ 'is-open': isSelectMenuOpen }"
    >

      <LoadingOverlay type="light" icon-size="medium" v-if="field.isLoading" />

      <div class="icon-overlay">

        <img
          :src="selectedOption.iconUrl"
          class="selected-option-icon"
          :class="{ 'disabled': field.disabled }"
          v-if="selectedOption && selectedOption.iconUrl"
        />
        <component
          :is="selectedOption.icon"
          class="selected-option-icon"
          :class="{ 'disabled': field.disabled }"
          v-else-if="selectedOption && selectedOption.icon"
        />

        <img
          :src="selectedOption.secondaryIconUrl"
          class="selected-option-secondary-icon"
          :class="{ 'disabled': field.disabled }"
          v-if="selectedOption && selectedOption.secondaryIconUrl"
        />
        <component
          :is="selectedOption.secondaryIcon"
          class="selected-option-secondary-icon"
          :class="{ 'disabled': field.disabled }"
          v-else-if="selectedOption && selectedOption.secondaryIcon"
        />

        <ChevronIcon
          class="chevron-icon"
          :class="field.disabled ? 'text-gray-500' : 'text-black'"
        />

        <select
          :id="uuid"
          ref="formElement"
          v-model="field.value"
          @mousedown="onSelectClick"
          :disabled="field.disabled"
          :required="field.required"
          :class="selectInputClasses"
        >
          <option value="">{{ field.placeholder }}</option>
          <template v-if="optionGroupNames.length > 1">
            <optgroup
              :key="optionGroupName"
              :label="optionGroupName"
              v-for="(optionGroupOptions, optionGroupName) in filteredOptionsByGroup"
            >
              <option
                :key="option.value"
                :value="option.value"
                v-for="option in optionGroupOptions"
                >
                {{ option.label }}
              </option>
            </optgroup>
          </template>
          <template v-else>
            <option
              :key="option.value"
              :value="option.value"
              v-for="option in filteredOptions"
              >
              {{ option.label }}
            </option>
          </template>
        </select>

      </div>

      <div class="filter-container icon-overlay" v-if="isSearchable && isSelectMenuOpen">

        <SearchIcon class="search-icon" />

        <input
          type="search"
          ref="filterInput"
          v-model="filterQuery"
          placeholder="Search..."
          class="filter-input has-icon"
          @keydown="onFilterInputKeydown"
        />

      </div>

      <div
        ref="selectMenu"
        class="select-menu"
        :class="selectMenuClasses"
      >
        <div class="no-filter-results" v-if="filteredOptions.length === 0">
          No results found.
        </div>
        <template v-else>
          <div
            class="option-group"
            :key="optionGroupName"
            :label="optionGroupName"
            v-for="(optionGroupOptions, optionGroupName) in filteredOptionsByGroup"
          >
            <div class="option-group-name" v-if="optionGroupNames.length > 1">{{ optionGroupName }}</div>
            <div
              :key="option.value"
              class="option-container"
              @mouseup="onOptionClick"
              v-for="option in optionGroupOptions"
              :value="JSON.stringify(option.value)"
              :class="{ 'is-selected': field.value === option.value, 'focused': focusedOptionIndex !== null && option.value === focusedOptionValue }"
            >
              <template v-if="showIcons">
                <img :src="option.iconUrl" class="option-icon" v-if="option.iconUrl" />
                <component :is="option.icon" class="option-icon" v-else-if="option.icon" />
                <img :src="option.secondaryIconUrl" class="option-secondary-icon" v-if="option.secondaryIconUrl" />
                <component :is="option.secondaryIcon" class="option-secondary-icon" v-else-if="option.secondaryIcon" />
              </template>
              <div class="option-text-container">
                <template v-if="hasOptionsWithDescriptions && showDescriptions">
                  <div class="option-title" v-if="option.label">
                    {{ option.label }}
                    <ExternalLinkIcon class="external-link-icon" v-if="option.showExternalLinkIcon" />
                  </div>
                  <div class="option-description">
                    <img :src="option.descriptionIconUrl" class="description-icon" v-if="option.descriptionIconUrl" />
                    <component :is="option.descriptionIcon" class="description-icon" v-else-if="option.descriptionIcon" />
                    {{ option.description }}
                  </div>
                </template>
                <template v-else>
                  <div>
                    {{ option.label }}
                    <ExternalLinkIcon class="external-link-icon" v-if="option.showExternalLinkIcon" />
                  </div>
                </template>
              </div>
              <span class="flex-grow" />
              <CheckIcon class="selected-icon" v-if="field.value === option.value" />
            </div>
          </div>
        </template>
        <slot name="extra-content" />
      </div>

    </div>

    <FormNote
      v-if="field.note"
      :formName="formName"
      :fieldName="fieldName"
    />

    <FormError
      v-if="showError"
      :formName="formName"
      :fieldName="fieldName"
    />

  </div>
</template>

<script>

  import { toRefs } from 'vue'

  import useFormField from '@/composables/useFormField'

  import FormNote from '@/components/forms/FormNote.vue'
  import FormError from '@/components/forms/FormError.vue'
  import FormLabel from '@/components/forms/FormLabel.vue'
  import FormLabelDescription from '@/components/forms/FormLabelDescription.vue'

  import EventBus from '@/components/utils/EventBus.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'

  import CheckIcon from '@/assets/icons/check.svg'
  import SearchIcon from '@/assets/icons/search.svg'
  import ChevronIcon from '@/assets/icons/chevron.svg'
  import ExternalLinkIcon from '@/assets/icons/external-link.svg'

  export default {
    components: {
      FormNote,
      FormError,
      FormLabel,
      CheckIcon,
      SearchIcon,
      ChevronIcon,
      LoadingOverlay,
      ExternalLinkIcon,
      FormLabelDescription,
    },
    props: {
      formName: {
        type: String,
        required: true,
      },
      fieldName: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        default: 'normal',
        validator: (value) => {
          return value === 'normal' || value === 'rich'
        },
      },
      isSearchable: {
        type: Boolean,
        default: false,
      },
      showLabel: {
        type: Boolean,
        default: true,
      },
      showError: {
        type: Boolean,
        default: true,
      },
      showDescriptions: {
        type: Boolean,
        default: true,
      },
      showIcons: {
        type: Boolean,
        default: true,
      },
    },
    setup(props) {
      return useFormField(toRefs(props))
    },
    computed: {
      $body() {
        return document.body
      },
      $selectMenu() {
        return this.$refs.selectMenu
      },
      $selectContainer() {
        return this.$refs.selectContainer
      },
      selectedOption() {
        return this.field.options.find((option) => {
          return option.value === this.field.value
        })
      },
      selectInputClasses() {
        return {
          error: !!this.field.error,
          'showing-placeholder': this.field.value === '',
          'has-icon': this.selectedOption && (this.selectedOption.icon || this.selectedOption.iconUrl),
        }
      },
      selectMenuClasses() {
        return {
          [this.type]: true,
          'is-open': this.isSelectMenuOpen,
          'show-dividers': this.hasOptionsWithDescriptions && this.showDescriptions && this.type !== 'rich',
        }
      },
      filteredOptions() {

        if (!this.isSearchable || !this.filterQuery) {
          return this.field.options
        }

        const regex = new RegExp(`.*${this.filterQuery}.*`, 'i')

        return this.field.options.filter((option) => {

          const labelMatch = regex.test(option.label)

          // prioritize a label match over a description match
          if (labelMatch) return labelMatch

          if (this.hasOptionsWithDescriptions && this.showDescriptions) {
            const descriptionMatch = regex.test(option.description)
            if (descriptionMatch) return descriptionMatch
          }

          // match against contract addresses if these options appear to have
          //  contract records associated with them...
          if (option.apiRecord && option.apiRecord.address) {
            const addressMatch = regex.test(option.apiRecord.address)
            if (addressMatch) return addressMatch
          }

          return false

        })

      },
      filteredOptionsByGroup() {

        const optionGroups = {}

        this.filteredOptions.forEach((filteredOption) => {
          const optionGroupName = filteredOption.optionGroupName || 'OTHER'
          optionGroups[optionGroupName] ||= []
          optionGroups[optionGroupName].push(filteredOption)
        })

        return optionGroups

      },
    },
    data() {
      return {
        filterQuery: '',
        isSelectMenuOpen: false,
        focusedOptionIndex: null,
        focusedOptionValue: null,
      }
    },
    watch: {
      isSelectMenuOpen(newValue, oldValue) {
        if (newValue) {

          this.addEventListeners()

          EventBus.$emit('select:opened', this.$selectContainer)

          // if searchable, auto-focus the filter input
          if (this.isSearchable) {

            // @NOTE: this must come in a $nextTick() since the filterInput $ref
            //  won't exist until isSelectMenuOpen is true due to the v-if
            //
            // @NOTE: also, for some reason this doesn't work reliably if you
            //  try to make this.$refs.filterInput a computed property
            this.$nextTick(() => {
              this.$refs.filterInput.focus()
            })

          }

        } else {
          this.filterQuery = ''
          this.focusedOptionIndex = null
          this.$selectMenu.scrollTop = 0

          this.removeEventListeners()
        }
      },
      filteredOptions(newValue, oldValue) {

        if (newValue.length === 0 || oldValue.length === 1) {
          this.focusedOptionIndex = null
        }

        if (newValue.length === 1) {
          this.focusedOptionIndex = 0
          this.$nextTick(() => {
            this.scrollOptionIntoView(this.focusedOptionIndex)
          })
        }

      },
    },
    mounted() {
      EventBus.$on('select:opened', this.onGlobalSelectOpen)
    },
    beforeUnmount() {
      this.removeEventListeners()
      EventBus.$off('select:opened', this.onGlobalSelectOpen)
    },
    methods: {
      // @NOTE: Element.scrollIntoView() doesn't seem to work super well... it
      //  takes the whole page container (behind the search) into account, which
      //  scrolls the page weirdly when navigating through search results with
      //  the keyboard... so let's just write our own logic for that
      scrollOptionIntoView(optionIndex) {

        this.focusedOptionValue = this.filteredOptions[optionIndex].value

        const $target = this.$selectMenu.querySelector(`.option-container[value="${JSON.stringify(this.focusedOptionValue).replaceAll('"', '\\"')}"]`)

        if (!$target) {
          this.$selectMenu.scrollTop = 0
          return
        }

        const targetTop = $target.offsetTop
        const targetBottom = targetTop + $target.offsetHeight

        const lowerBound = this.$selectMenu.scrollTop
        const upperBound = lowerBound + this.$selectMenu.offsetHeight

        if (targetTop < lowerBound) this.$selectMenu.scrollTop = targetTop
        if (targetBottom > upperBound) this.$selectMenu.scrollTop += targetBottom - upperBound

      },
      // @NOTE: some values (like database IDs) are numbers, but reading them
      //  via getAttribute vill always return a string - so instead we JSON
      //  encode the value when binding to the "value" attribute and decode it
      //  here
      onOptionClick($event) {
        const newValue = JSON.parse($event.target.getAttribute('value'))
        this.isSelectMenuOpen = false
        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: this.fieldName,
          formName: this.formName,
          newValue,
        })
      },
      onSelectClick($event) {

        // @NOTE: it may seem weird to focus & blur the input back to back like
        //  this, but the explicit focus() causes any other input that may have
        //  focus to blur first, before we blur the select input itself
        //
        // this fixes a bug where if you are focused on a separate element, the
        //  focus stays there when you click the select input
        if ($event) {
          $event.target.focus()
          $event.target.blur()
          $event.preventDefault()
        }

        this.isSelectMenuOpen = !this.isSelectMenuOpen

      },
      onFilterInputKeydown($event) {
        switch ($event.code) {
          case 'ArrowUp':
            if (this.focusedOptionIndex === null) this.focusedOptionIndex = this.filteredOptions.length
            this.focusedOptionIndex -= 1
            if (this.focusedOptionIndex === -1) this.focusedOptionIndex = this.filteredOptions.length - 1
            this.scrollOptionIntoView(this.focusedOptionIndex)
            $event.preventDefault()
            break

          case 'ArrowDown':
            if (this.focusedOptionIndex === null) this.focusedOptionIndex = -1
            this.focusedOptionIndex += 1
            if (this.focusedOptionIndex === this.filteredOptions.length) this.focusedOptionIndex = 0
            this.scrollOptionIntoView(this.focusedOptionIndex)
            $event.preventDefault()
            break

          case 'Space':
            if (this.filterQuery) return
          // fall through

          case 'Enter': {
            if (this.focusedOptionIndex === null) return
            this.$store.commit('forms/SET_FIELD_VALUE', {
              newValue: this.filteredOptions[this.focusedOptionIndex].value,
              fieldName: this.fieldName,
              formName: this.formName,
            })
            this.focusedOptionIndex = null
            this.isSelectMenuOpen = false
            $event.preventDefault()
            break
          }

          case 'Escape':
            this.isSelectMenuOpen = false
            break

          default:
        }
      },
      addEventListeners() {
        this.$body.addEventListener('mousedown', this.bodyClickHandler)
        if (this.$selectContainer) {
          this.$selectContainer.addEventListener('mousedown', this.selectContainerClickHandler)
        }
      },
      removeEventListeners() {
        this.$body.removeEventListener('mousedown', this.bodyClickHandler)
        if (this.$selectContainer) {
          this.$selectContainer.removeEventListener('mousedown', this.selectContainerClickHandler)
        }
      },
      bodyClickHandler($event) {
        this.isSelectMenuOpen = false
      },
      selectContainerClickHandler($event) {
        $event.stopPropagation()
      },
      // this method is called whenever a select is opened site-wide and lets us
      //  close this local select menu in response
      onGlobalSelectOpen($selectContainer) {
        if ($selectContainer !== this.$selectContainer) {
          this.isSelectMenuOpen = false
        }
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .select-container
    @apply w-full
    @apply relative

    select
      @apply z-10

    .chevron-icon
      @apply rotate-0
      @apply transform
      @apply transition-transform

    &.is-open
      select
        box-shadow: 0 0 0 2px theme('colors.primary.300'), 0 0 0 4px rgba(255, 138, 164, 0.3)

      .chevron-icon
        @apply -rotate-180

  .filter-container
    @apply z-30
    @apply top-0
    @apply left-0
    @apply absolute

    .filter-input
      @apply pr-4

  .select-menu
    @apply z-20
    @apply w-full
    @apply hidden
    @apply bg-white
    @apply shadow-xl
    @apply rounded-bl-sm
    @apply rounded-br-sm
    @apply overflow-y-auto

    @apply ring-1
    @apply ring-gray-400

    margin-top: 4px
    max-height: 20rem

    &.show-dividers
      .option-container + .option-container
        @apply border-t
        @apply border-gray-400

    &.is-open
      @apply z-20
      @apply block
      @apply absolute

      @apply top-full

    .option-group-name
      @apply pt-4
      @apply px-4
      @apply font-bold
      @apply text-gray-600

    .option-container
      @apply py-4
      @apply px-4
      @apply relative
      @apply text-base
      @apply items-start
      @apply text-gray-800
      @apply cursor-pointer

      >svg
      >img
      >div
        @apply pointer-events-none

      &:hover
      &.focused
        @apply bg-purple-200
        @apply text-gray-1000

      &.is-selected
        @apply bg-primary-200

    .option-text-container
      @apply flex
      @apply flex-col
      @apply flex-grow

      max-width: 26rem

      .option-title
        @apply mb-1
        @apply font-bold

    .option-icon
      @apply w-6
      @apply h-6
      @apply mr-2

      min-width: theme('width.6')

    .option-secondary-icon
      @apply w-4
      @apply h-4
      @apply top-7
      @apply left-7
      @apply border-2
      @apply absolute
      @apply bg-white
      @apply rounded-full

    .selected-icon
      @apply w-4
      @apply h-4
      @apply ml-4

      min-width: theme('width.4')
      min-height: theme('width.4')

    .option-description
      @apply flex
      @apply items-start

      .description-icon
        @apply w-4
        @apply h-4
        @apply mt-1
        @apply mr-1

        min-width: theme('width.4')

    .external-link-icon
      @apply w-3
      @apply h-3
      @apply ml-1
      @apply inline
      @apply align-baseline

      min-width: theme('width.3')
      min-height: theme('width.3')

    &.rich
      .option-description
        @apply text-xs
        @apply text-gray-600

      .option-icon
        @apply w-12
        @apply h-12
        @apply mr-2

        min-width: theme('width.12')

      .description-icon
        @apply mt-0

  :deep(.sticky-button)
    @apply p-3
    @apply w-full
    @apply sticky
    @apply bottom-0
    @apply bg-white
    @apply font-normal
    @apply rounded-none
    @apply text-gray-800

    @apply border-t
    @apply border-gray-400

    @apply flex
    @apply justify-center

    &:hover:not([disabled])
    &:focus:not([disabled])
      @apply no-underline
      @apply bg-purple-200
      @apply text-gray-800

    svg
      @apply w-6
      @apply h-6
      @apply mr-1
      @apply text-primary-500

</style>
