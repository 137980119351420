<template>

  <Header />

  <main>
    <div class="container p-4 sm:p-8 mx-auto space-y-12">

      <!--

        @NOTE: Tailwind needs all dynamically-constructed class names in the
         source so it won't purge them during build

        bg-primary-100
        bg-primary-200
        bg-primary-300
        bg-primary-400
        bg-primary-500
        bg-primary-600
        bg-primary-700
        bg-primary-800
        bg-primary-900

        bg-purple-100
        bg-purple-200
        bg-purple-300
        bg-purple-400
        bg-purple-500
        bg-purple-600
        bg-purple-700
        bg-purple-800
        bg-purple-900
        bg-purple-1000

        bg-gray-100
        bg-gray-200
        bg-gray-300
        bg-gray-400
        bg-gray-500
        bg-gray-600
        bg-gray-700
        bg-gray-800
        bg-gray-900
        bg-gray-1000

        bg-danger-100
        bg-danger-200
        bg-danger-300
        bg-danger-400
        bg-danger-500
        bg-danger-600
        bg-danger-700
        bg-danger-800
        bg-danger-900
        bg-danger-1000

        bg-warning-100
        bg-warning-300
        bg-warning-500
        bg-warning-700
        bg-warning-900

        bg-success-100
        bg-success-300
        bg-success-500
        bg-success-700
        bg-success-900

        bg-neutral-100
        bg-neutral-300
        bg-neutral-500
        bg-neutral-700
        bg-neutral-900

        bg-lime-100
        bg-lime-300
        bg-lime-500
        bg-lime-700
        bg-lime-900

        bg-grape-100
        bg-grape-300
        bg-grape-500
        bg-grape-700
        bg-grape-900

        bg-orange-100
        bg-orange-300
        bg-orange-500
        bg-orange-700
        bg-orange-800
        bg-orange-900

        bg-magenta-100
        bg-magenta-300
        bg-magenta-500
        bg-magenta-700
        bg-magenta-900

        bg-cyan-100
        bg-cyan-300
        bg-cyan-500
        bg-cyan-700
        bg-cyan-900

      -->

      <section>
        <h2>Colors</h2>
        <div class="flex flex-wrap" :key="color" v-for="(weights, color) in swatches">
          <span
            :key="weight"
            class="swatch"
            v-for="weight in weights"
            :title="`${color}-${weight}`"
            :class="`bg-${color}-${weight}`"
          >
            {{ weight }}
          </span>
        </div>
      </section>

      <section>
        <h2>Icons</h2>

        <h3>Actions</h3>
        <div
          :key="actionOption.id"
          :title="actionOption.label"
          class="w-12 h-12 p-1 inline-block align-bottom"
          v-for="actionOption in $store.state.app.actionOptions"
        >
          <img class="w-full h-full" :src="actionOption.iconUrl" />
        </div>

        <h3>Triggers</h3>
        <div
          :key="triggerOption.id"
          :title="triggerOption.label"
          class="w-12 h-12 p-1 inline-block align-bottom"
          v-for="triggerOption in $store.state.app.triggerOptions"
        >
          <img class="w-full h-full" :src="triggerOption.iconUrl" />
        </div>

        <h3>Networks</h3>
        <div
          :key="networkOption.id"
          :title="networkOption.label"
          class="w-12 h-12 p-1 inline-block align-bottom"
          v-for="networkOption in $store.state.app.networkOptions"
        >
          <img class="w-full h-full" :src="networkOption.iconUrl" />
        </div>

        <template
          :key="networkSlug"
          v-for="(networkContractOptionsByType, networkSlug) in $store.state.app.networkContractOptionsByTypeSlugMap"
        >
          <h3>{{ titleCase(networkSlug.replace('-', ' ')) }} Tokens</h3>
          <template
            :key="contractType"
            v-for="(networkContractOptions, contractType) in networkContractOptionsByType"
          >
            <div
              :key="contractOption.id"
              v-for="contractOption in networkContractOptions"
              @click="copyText(contractOption.apiRecord.address)"
              class="cursor-pointer w-12 h-12 p-1 inline-block align-bottom"
              :title="`${contractOption.apiRecord.symbol} | ${contractOption.apiRecord.address}`"
            >
              <img class="w-full h-full" :src="contractOption.iconUrl" /> {{ contractOption.address }}
            </div>
          </template>
        </template>

      </section>

      <section>
        <h2>Buttons</h2>
        <div class="space-y-8 sm:space-y-2">
          <div class="button-container">
            <button>Primary</button>
            <button class="primary outline">Outline</button>
            <button class="secondary">Secondary</button>
            <button class="tertiary">Tertiary</button>
            <button class="danger">Danger</button>
          </div>
          <div class="button-container">
            <button disabled>Disabled</button>
            <button class="primary outline" disabled>Disabled</button>
            <button class="secondary" disabled>Disabled</button>
            <button class="tertiary" disabled>Disabled</button>
            <button class="danger" disabled>Disabled</button>
          </div>
          <div class="button-container">
            <button class="small">Small</button>
            <button class="small primary outline">Small</button>
            <button class="small secondary">Small</button>
            <button class="small tertiary">Small</button>
            <button class="small danger">Small</button>
            <button class="small ghost"><TrashIcon /></button>
          </div>
        </div>
      </section>

      <section>
        <h2>Button Groups</h2>
        <div class="button-group">
          <button class="small tertiary">One</button>
          <button class="small tertiary">Two</button>
          <button class="small tertiary">Three</button>
          <button class="small tertiary">Four</button>
        </div>
      </section>

      <section>
        <h2>Content Switcher</h2>
        <FormRadio
          :showLabel="false"
          :showError="false"
          :formName="formName"
          type="content-switcher"
          fieldName="contentSwitcher"
        />
      </section>

      <section id="links">
        <h2>Links</h2>
        <div class="flex">
          <div class="w-1/2 p-4 border border-purple-1000 bg-purple-1000 text-white flex items-start flex-col space-y-4">
            <a href="#links">Link - enabled</a>
            <a href="#links" class="disabled">Link - disabled</a>
          </div>
          <div class="subtle w-1/2 p-4 border border-purple-1000 bg-white text-black flex items-start flex-col space-y-4">
            <a href="#links">Link - enabled</a>
            <a href="#links" class="disabled">Link - disabled</a>
          </div>
        </div>
      </section>

      <section id="tooltips">
        <h2>Tooltips</h2>
        <div class="flex">
          <div class="mr-2 flex-shrink-0">
            <Tooltip>
              <template #trigger>
                <InfoIcon class="w-6 h-6" />
              </template>
              <img class="mx-auto my-4 border w-1/2" src="@/assets/images/satellite.png" />
              <p class="mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </Tooltip>
          </div>
          Hover over the icon!
        </div>
        <div class="flex justify-end">
          Here's a tooltip that's close to the right edge of the screen.
          <div class="ml-2 flex-shrink-0">
            <Tooltip>
              <template #trigger>
                <InfoIcon class="w-6 h-6" />
              </template>
              <div class="flex items-center">
                <ToastIcon class="w-4 h-4 mr-2" /> Here's some toast.
              </div>
            </Tooltip>
          </div>
        </div>
      </section>

      <section>
        <h2>Alerts</h2>
        <div class="alert">
          A neutral info message
        </div>
        <div class="alert error">
          An error or danger message
        </div>
        <div class="alert warning">
          A warning message
        </div>
        <div class="alert success">
          A success message
        </div>
      </section>

      <section>
        <h2>Toasts</h2>
        <button class="small" @click="triggerToast">
          <ToastIcon class="w-4 h-4 mr-2" /> Trigger a Toast!
        </button>
      </section>

      <section>
        <h2>Modals</h2>
        <button class="small" @click="openModal">
          Open a Modal!
        </button>
        <button class="small mt-4" @click="openModal('wide')">
          Open a Wide Modal!
        </button>
      </section>

      <section>
        <h2>Tabs</h2>
        <Tabs>
          <template #tabs>
            <button
              class="link tab"
              @click="currentTab = 1"
              :class="{ 'active': currentTab === 1 }"
            >
              Overview
            </button>
            <button
              class="link tab"
              @click="currentTab = 2"
              :class="{ 'active': currentTab === 2 }"
            >
              History
            </button>
            <button
              class="link tab"
              @click="currentTab = 3"
              :class="{ 'active': currentTab === 3 }"
            >
              Templates
            </button>
          </template>
          <template #tab-content>
            <template v-if="currentTab === 1">
              <p>Tab 1 contents!</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </template>
            <template v-if="currentTab === 2">
              <p>Tab 2 contents!</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </template>
            <template v-if="currentTab === 3">
              <p>Tab 3 contents!</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </template>
          </template>
        </Tabs>
      </section>

      <section>
        <h2>Tags</h2>
        <div class="tag-container">
          <Tag
            :key="network.id"
            v-for="network in $store.state.app.networkOptions"
          >
            <img :src="network.iconUrl" class="icon" />
            {{ network.label }}
          </Tag>
        </div>
        <div class="tag-container">
          <Tag :clickable="true">
            This is a really long tag that will overflow and truncate. Lorem ipsum
            dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.
          </Tag>
        </div>
        <div class="tag-container">
          <template
            :key="networkSlug"
            v-for="(networkContractOptionsByType, networkSlug) in $store.state.app.networkContractOptionsByTypeSlugMap"
          >
            <template
              :key="contractType"
              v-for="(networkContractOptions, contractType) in networkContractOptionsByType"
            >
              <Tag
                :clickable="true"
                :key="contractOption.id"
                v-for="contractOption in networkContractOptions"
              >
                <img :src="contractOption.iconUrl" class="icon" />
                {{ contractOption.apiRecord.symbol }}
              </Tag>
            </template>
          </template>
        </div>
      </section>

      <section>
        <h2>Badges</h2>
        <div class="space-x-2">
          <span class="badge subtle">1</span>
          <span class="badge subtle">12</span>
          <span class="badge subtle">123</span>
          <span class="badge subtle">1234</span>
          <span class="badge subtle">1235</span>
        </div>
        <div class="mt-2 space-x-2">
          <span class="badge purple subtle">1</span>
          <span class="badge purple subtle">12</span>
          <span class="badge purple subtle">123</span>
          <span class="badge purple subtle">1234</span>
          <span class="badge purple subtle">1235</span>
        </div>
        <div class="mt-2 space-x-2">
          <span class="badge">1</span>
          <span class="badge">12</span>
          <span class="badge">123</span>
          <span class="badge">1234</span>
          <span class="badge">1235</span>
        </div>
        <div class="mt-2 space-x-2">
          <span class="badge purple">1</span>
          <span class="badge purple">12</span>
          <span class="badge purple">123</span>
          <span class="badge purple">1234</span>
          <span class="badge purple">1235</span>
        </div>
      </section>

      <section>
        <h2>Rarity Labels</h2>
        <span class="rarity-label common">
          Common
        </span>
        <span class="rarity-label uncommon">
          Uncommon
        </span>
        <span class="rarity-label rare">
          Rare
        </span>
        <span class="rarity-label very-rare">
          Very Rare
        </span>
        <span class="rarity-label legendary">
          Legendary
        </span>
      </section>

      <section>
        <h2>Overflow Menus</h2>
        <OverflowMenu>
          <template #trigger>
            <button class="small">Click me!</button>
          </template>
          <div class="p-4">
            <p>I'm an overflow menu and I can hold rich content!</p>
            <img class="my-4 border w-full" src="@/assets/images/satellite.png" />
            <button class="small tertiary">I'm a button!</button>
          </div>
        </OverflowMenu>
      </section>

      <section>
        <h2>Filters &amp; Searching</h2>
        <PaginationFilters filterFormName="patchHistoryFiltersForm" paginationListName="userPatchEvents" />
      </section>

      <section>
        <h2>Forms</h2>
        <form class="space-y-1" @submit="submit($event)">

          <FormInput :formName="formName" fieldName="name" />
          <FormInput :formName="formName" fieldName="email" />
          <FormInput :formName="formName" fieldName="age" />
          <FormSelect :formName="formName" fieldName="favoriteBlockchain" :isSearchable="true" />
          <FormSelect :formName="formName" fieldName="titleAndDescription" />
          <FormSelect :formName="formName" fieldName="sectionedAndRich" type="rich" :isSearchable="true" />
          <FormSelect :formName="formName" fieldName="income" />
          <FormInput :formName="formName" fieldName="secrets" />
          <FormInput :formName="formName" fieldName="listOfAddresses" />
          <FormCheckbox :formName="formName" fieldName="checkboxAgree" />
          <FormCheckbox :formName="formName" fieldName="disabledCheckbox" />
          <FormCheckbox :formName="formName" fieldName="multiCheckbox" />
          <RichChecklist :formName="formName" fieldName="contractEventChecklist" type="contractABIEntries" />
          <FormRadio :formName="formName" fieldName="breathCount" />
          <FormRadio :formName="formName" fieldName="timerange" type="button-group" />
          <FormCheckbox :is-toggle="true" :formName="formName" fieldName="enablePartyMode" />

          <div class="text-right">
            <button type="submit" class="mt-4" :disabled="form.isLoading || !isFormValid">Submit</button>
          </div>

        </form>
      </section>

    </div>
  </main>

  <Footer />

</template>

<script>

  import useForm from '@/composables/useForm'
  import useFilters from '@/composables/useFilters'

  import Tabs from '@/components/page/Tabs.vue'
  import Header from '@/components/page/Header.vue'
  import Footer from '@/components/page/Footer.vue'

  import InfoIcon from '@/assets/icons/info.svg'
  import ToastIcon from '@/assets/icons/toast.svg'
  import TrashIcon from '@/assets/icons/trash.svg'

  import FormRadio from '@/components/forms/FormRadio.vue'
  import FormInput from '@/components/forms/FormInput.vue'
  import FormSelect from '@/components/forms/FormSelect.vue'
  import FormCheckbox from '@/components/forms/FormCheckbox.vue'
  import RichChecklist from '@/components/forms/RichChecklist.vue'

  import Tag from '@/components/utils/Tag.vue'
  import Tooltip from '@/components/utils/Tooltip.vue'
  import OverflowMenu from '@/components/utils/OverflowMenu.vue'
  import PaginationFilters from '@/components/utils/PaginationFilters.vue'

  export default {
    components: {
      Tag,
      Tabs,
      Header,
      Footer,
      Tooltip,
      InfoIcon,
      ToastIcon,
      TrashIcon,
      FormRadio,
      FormInput,
      FormSelect,
      FormCheckbox,
      OverflowMenu,
      RichChecklist,
      PaginationFilters,
    },
    setup(props) {

      // data
      const formName = 'styleGuideForm'

      // composables
      const { titleCase } = useFilters()
      const { form, isFormValid } = useForm({ formName })

      return {
        titleCase,

        form,
        formName,
        isFormValid,
      }

    },
    data() {
      return {
        currentTab: 1,
        currentContentSwitcherIndex: 0,
        swatches: {
          primary: [100, 200, 300, 400, 500, 600, 700, 800, 900],
          purple: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
          gray: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
          danger: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
          orange: [100, 300, 500, 700, 800, 900],
          lime: [100, 300, 500, 700, 900],
          grape: [100, 300, 500, 700, 900],
          magenta: [100, 300, 500, 700, 900],
          cyan: [100, 300, 500, 700, 900],
          warning: [100, 300, 500, 700, 900],
          success: [100, 300, 500, 700, 900],
          neutral: [100, 300, 500, 700, 900],
        },
      }
    },
    methods: {
      submit($event) {
        if ($event) $event.preventDefault()
        this.$store.dispatch('forms/GET_FORM_DATA', this.formName).then(console.log)
      },
      triggerToast() {
        this.$store.dispatch('toast/CREATE_TOAST', {
          text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit!',
          type: (() => {
            const number = Math.random()
            if (number < 0.25) return 'neutral'
            if (number < 0.5) return 'error'
            if (number < 0.75) return 'warning'
            return 'success'
          })(),
        })
      },
      openModal(mode) {
        this.$store.dispatch('modals/OPEN_MODAL', {
          mode,
          name: 'TestModal',
          props: {
            title: 'Let\'s start a party! 🎉',
            someOtherValue: 'WOO HOO!',
            cancelAction() {
              this.$store.dispatch('toast/CREATE_TOAST', {
                text: 'Okay, never mind then...',
                type: 'error',
              })
            },
            action() {
              this.$store.dispatch('toast/CREATE_TOAST', {
                text: 'Man, you really closed the hell outta that modal!',
                type: 'success',
              })
            },
          },
        })
      },
      copyText(text) {
        navigator.clipboard.writeText(text)
      },
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/rarity.styl'

  main
    @apply bg-white

  h3
    @apply mt-8

  .container
    max-width: 48rem

  .swatch
    @apply p-2
    @apply text-black

    @apply flex
    @apply flex-grow
    @apply items-center
    @apply justify-center

  .button-container
    @apply space-y-2
    @apply space-x-0

    @apply flex
    @apply flex-col
    @apply items-center

    button:not(.ghost)
      @apply w-full

    +breakpoint(sm)
      @apply flex-row
      @apply space-x-2
      @apply space-y-0

      button:not(.ghost)
        @apply w-auto

  .rarity-label
    @apply mr-2

</style>
